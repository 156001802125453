<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-container
      fill-height
      fluid
    >
      <v-card-title class="float-left pa-0">
        <v-app-bar
          flat
          class="white pa-0"
          elevate-on-scroll
          dense
        >
          <v-toolbar-title
            data-cy="alerthistory_title"
          >
            <router-link
              :to="{ name: 'AlertCasePool' }"
              class="text-decoration-none"
              style="color: inherit"
            >
              {{ $t('alert_case.title') }}
            </router-link>
            <span class="font-weight-medium grey--text text-body-1">
              <v-icon>mdi-chevron-right</v-icon>
              {{ $t('alert_case_details.title', { case_id }) }}
            </span>
          </v-toolbar-title>
          <v-spacer />
        </v-app-bar>
      </v-card-title>
      <AlertCaseDetailsContent :case-id="case_id" />
    </v-container>
  </v-card>
</template>

<script>
import AlertCaseDetailsContent from '../forms/AlertCaseDetailsContent'

export default {
  components: {
    AlertCaseDetailsContent
  },
  computed: {
    case_id: {
      get () {
        return this.$route.params.case_id
      },
      set (value) {
        this.$router.push({
          name: 'AlertCaseDetails',
          params: {
            case_id: value
          }
        })
      }
    }
  },
  mounted () {
    this.$store.commit('scan/CLEAR_CACHED_ENTITY_DETAILS')
  }
}
</script>

<style scoped>

</style>
